import { createRouter, createWebHistory } from "vue-router";
import DolbyAtmos from "./components/DolbyAtmos.vue";
import DolbyVision from "./components/DolbyVision.vue";
import HdrTools from "./components/HdrTools.vue";
import UserInfo from "./components/UserInfo.vue";

const routes = [
  {
    path: "/atmos",
    name: "atmos",
    component: DolbyAtmos,
  },
  {
    path: "/vision",
    name: "vision",
    component: DolbyVision,
  },
  {
    path: "/hdr10",
    name: "hdr10",
    component: HdrTools,
  },
  {
    path: "/user",
    name: "user",
    component: UserInfo,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
