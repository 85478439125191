<template>
  <nav class="navbar navbar-dark bg-dark navbar-expand-lg sticky-top">
    <div class="container-fluid">
      <span class="navbar-brand">
        <div class="logo">DiVo</div>
      </span>
      <div class="collapse navbar-collapse" v-if="logged">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :to="'/vision'">
              <a class="nav-link active">Dolby Vision</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/atmos'">
              <a class="nav-link active">Dolby Atmos</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/hdr10'">
              <a class="nav-link active">HDR10</a>
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav d-flex">
          <li class="nav-item">
            <router-link :to="'/user'">
              <a class="nav-link active link">{{ displayName }}</a>
            </router-link>
          </li>
          <li class="nav-item">
            <a class="btn btn-outline-danger" @click="logOut()"
              ><i class="bi bi-box-arrow-right"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <notifications position="top center" />
  <div class="container-fluid bg" v-if="!logged">
    <center>
      <div class="sign-in">
        <a class="btn btn-secondary" @click="logIn()">
          Please log in or create an account
        </a>
      </div>
      <br />
      <div class="card" style="width: 30%">
        <div class="card-body">
          <h3 class="card-title">
            <div class="logo_neg">DiVo</div>
          </h3>
          <br />
          <span style="text-align: left; padding-left: 50px">
            <b>A Dolby Vision / Dolby Atmos tool</b> is a simple yet powerful
            Drag-and-Drop web application to parse and edit :<br />
            - Dolby Atmos XML file : ebuCoreMain<br />
            - Dolby Vision XML file : DolbyLabsMDF<br />
            <br />
            <b>HDR10</b> metadata renderer
          </span>
          <br /><br />
        </div>
      </div>
    </center>
  </div>
  <template v-if="logged">
    <router-view></router-view>
  </template>
  <footer class="flex-shrink-0 py-3 bg-dark text-white-50 fixed_footer">
    <div class="container text-left">
      <div class="row">
        <div class="col-1 col-md-1">
          <small>Our tools</small>
        </div>
        <div class="col-4 col-md-4">
          <small>
            <span class="nav-item">
              <a
                href="https://cpl.fishtank.cloud/"
                style="color: #e5e5e5"
                target="_blank"
                >CPL | Translator</a
              >
              <small> : DCP & IMF XML(CPL & PKL) parser</small>
            </span>
            <br />
            <span class="nav-item">
              <a
                href="https://divo.fishtank.cloud/"
                style="color: #e5e5e5"
                target="_blank"
                >DiVo</a
              >
              <small> : Dolby Vision & Atmos XML parser</small>
            </span>
            <br />
            <span class="nav-item">
              <a
                href="https://timeline.fishtank.cloud/"
                style="color: #e5e5e5"
                target="_blank"
                >Timeline</a
              >
              <small> : Dash, HLS and Timecode sandbox</small>
            </span>
          </small>
        </div>
        <div class="col-6 col-md-6 mb-6">
          <small>
            &copy; 2024
            <a
              href="https://fishtank.cloud/"
              style="color: #e5e5e5"
              target="_blank"
              >FishTank Cloud</a
            >
            All rights reserved.
            <br />
            <small
              >A division of
              <a
                href="https://trenchdigital.net"
                style="color: #e5e5e5"
                target="_blank"
                >Trench Digital LTD</a
              >.</small
            >
          </small>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import firebase from "firebase/compat/app";
import { firebaseConfig } from "./firebase/index";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

const googleProvider = new GoogleAuthProvider();
const firbaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firbaseApp);

export default {
  data() {
    return {
      displayName: "",
      logged: false,
    };
  },
  created() {
    document.title = "DiVo";
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        this.displayName = user.displayName;
        this.logged = true;
        this.$router.push("vision");
      }
    });
    if (this.logged) {
      this.$router.push("vision");
    }
  },
  methods: {
    logOut() {
      this.logged = false;
      this.displayName = null;
      signOut(firebaseAuth).then(() => {
        this.$forceUpdate();
      });
    },
    logIn() {
      signInWithPopup(firebaseAuth, googleProvider);
    },
  },
};
</script>

<style>
@import "./assets/main.css";
</style>
