<!-- FishTank 2024-->
<template lang="html">
  <div>
    <div class="container-fluid">
      <div
        class="card"
        style="
          width: 80%;
          position: absolute;
          top: 15%;
          left: 10%;
          margin-left: auto;
          margin-right: auto;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 200px;
        "
      >
        <div class="card-body">
          <h4>HDR10 XML</h4>
          <table class="table table-sm time_tab">
            <tbody>
              <tr>
                <td>MaxCLL <input v-model="maxCLL" /></td>
                <td>MaxFALL <input v-model="maxFALL" /></td>
                <td>
                  Mastering Display Color Volume
                  <select v-model="selectedMDCV">
                    <option
                      v-for="k in Object.keys(masteringDisplayColorVolume)"
                      :key="k"
                      :value="k"
                    >
                      {{ k }}
                    </option>
                  </select>
                </td>
                <td>
                  <a v-if="valid" class="btn btn-primary" @click="getFile()"
                    >Save as XML</a
                  >
                </td>
                <td>
                  <a class="btn btn-danger" @click="reset()">Reset</a>
                </td>
              </tr>
            </tbody>
          </table>
          <textarea
            v-if="xml != ''"
            v-model="xml"
            class="form-control"
            rows="17"
            placeholder="HDR10 XML"
          ></textarea>
          <p>
            MaxCLL (Maximum Content Light Level) indicates the maximum light
            level of any single pixel (in cd/m2 or nits) of the entire playback
            sequence.
          </p>
          <p>
            MaxFALL (Maximum Frame Average Light Level) indicates the maximum
            value of the frame average light level (in cd/m2 or nits) of the
            entire playback sequence. MaxFALL is calculated by averaging the
            decoded luminance values of all the pixels within a frame. MaxFALL
            is usually much lower than MaxCLL.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useFileSystemAccess } from "@vueuse/core";

export default {
  name: "TimeCode",
  data() {
    return {
      maxCLL: "",
      maxFALL: "",
      xml: "",
      selectedMDCV: "default",
      masteringDisplayColorVolume: {
        default: `<MasteringDisplayColorVolume>
  <DisplayPrimaries>
    <DisplayPrimary name="Red" x="0.68" y="0.32"/>
    <DisplayPrimary name="Green" x="0.265" y="0.69"/>
    <DisplayPrimary name="Blue" x="0.15" y="0.06"/>
  </DisplayPrimaries>
  <DisplayLuminance min="0.005" max="1000.0"/>
  <WhitePoint x="0.3127" y="0.329"/>
</MasteringDisplayColorVolume>`,
      },
      valid: false,
      toFile: useFileSystemAccess({
        types: [
          {
            description: "xml",
            accept: {
              "text/plain": [".xml"],
            },
          },
        ],
        excludeAcceptAllOption: true,
      }),
    };
  },
  watch: {
    maxCLL: {
      handler() {
        this.testInput();
      },
      deep: true,
    },
    maxFALL: {
      handler() {
        this.testInput();
      },
      deep: true,
    },
    selectedMDCV: {
      handler() {
        this.testInput();
      },
      deep: true,
    },
  },
  methods: {
    reset: function () {
      this.valid = false;
      this.maxCLL = "";
      this.maxFALL = "";
      this.xml = "";
    },
    testInput: function () {
      if (this.maxCLL != "" && this.maxFALL != "" && this.selectedMDCV != "") {
        let maxCLL = Number(this.maxCLL);
        let maxFALL = Number(this.maxFALL);
        if (!isNaN(maxCLL) && !isNaN(maxFALL)) {
          this.valid = true;
          this.renderXml();
        }
      }
    },
    getFile: function () {
      this.toFile.data = this.xml;
      this.toFile.saveAs();
    },
    renderXml: function () {
      this.xml = `<?xml version="1.0"?>
<hdr norm="HDR10">
  ${this.masteringDisplayColorVolume[this.selectedMDCV]}
  <PQMetadatas>
    <PQMetadata name="MaxCLL" value="${parseFloat(this.maxCLL).toFixed(2)}"/>
    <PQMetadata name="MaxFALL" value="${parseFloat(this.maxFALL).toFixed(2)}"/>
  </PQMetadatas>
</hdr>`;
    },
  },
};
</script>
